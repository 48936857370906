<template>
  <topbar-container
    v-if="profile._id"
    class="topbar is-unshrink"
    @mouseenter.native="isHover = true"
    @mouseleave.native="isHover = false"
  >
    <topbar-item
      v-if="isOnboardingVisible"
      :tabs-path="Object.values(routeTabs.onboarding)"
      class="is-unshrink mb-4"
      label="Onboarding"
      :iconComponent="isItemActive(Object.values(routeTabs.onboarding)) ? 'house-bold' : 'house'"
      to="/onboarding"
    >
    </topbar-item>
    <topbar-item
      v-if="configurationFramework.showCompanies"
      :label="$tc('generics.company', 2)"
      :tabs-path="Object.values(routeTabs.companies)"
      class="is-unshrink mb-4"
      :iconComponent="isItemActive(Object.values(routeTabs.companies)) ? 'buildings-2-bold' : 'buildings-2'"
      to="/partners?page=1"
    >
    </topbar-item>
    <topbar-item
      :label="$tc('generics.opportunity', 2)"
      :tabs-path="Object.values(routeTabs.opportunities)"
      :to="opportunityLink"
      class="is-unshrink mb-4"
      :iconComponent="isItemActive(Object.values(routeTabs.opportunities)) ? 'shopping-bag-bold' : 'shopping-bag'"
    >
    </topbar-item>
    <topbar-item
      :label="$tc('generics.candidate-cap', 2)"
      :tabs-path="Object.values(routeTabs.candidates)"
      class="is-unshrink mb-4"
      to="/coders?page=1"
      :iconComponent="isItemActive(Object.values(routeTabs.candidates)) ? 'user-square-bold' : 'user-square'"
    >
    </topbar-item>
    <topbar-item
      v-if="isCampaignsVisible"
      :label="$tc('campaigns.campaigns', 2)"
      :tabs-path="Object.values(routeTabs.campaigns)"
      class="is-unshrink mb-4"
      to="/campaigns"
      :iconComponent="isItemActive(Object.values(routeTabs.campaigns)) ? 'direct-up-bold' : 'direct-up'"
    >
    </topbar-item>
    <topbar-item
      :label="$t('generics.statistics')"
      :tabs-path="Object.values(routeTabs.statistics)"
      :to="processesStatsLink"
      class="is-unshrink mb-4"
      :iconComponent="isItemActive(Object.values(routeTabs.statistics)) ? 'chart-bold' : 'chart'"
    >
    </topbar-item>
    <topbar-item
      v-if="isBillingVisible"
      :label="$t('generics.billing')"
      :tabs-path="Object.values(routeTabs.billing)"
      :to="processesStatsLink"
      class="is-unshrink mb-4"
      :iconComponent="isItemActive(Object.values(routeTabs.billing)) ? 'receipt-item-bold' : 'receipt-item'"
    >
    </topbar-item>
    <topbar-item
      :label="$t('timeline.reminders')"
      :tabs-path="Object.values(routeTabs.reminders)"
      :to="remindersLink"
      class="is-unshrink mb-4 mt-auto"
      :iconComponent="isItemActive(Object.values(routeTabs.reminders)) ? 'vuesax-note-bold' : 'vuesax-note'"
    >
    </topbar-item>
    <topbar-item
      :label="$t('generics.lists')"
      :tabs-path="Object.values(routeTabs.lists)"
      :to="listsLink"
      class="is-unshrink"
      :iconComponent="isItemActive(Object.values(routeTabs.lists)) ? 'task-square-bold' : 'task-square'"
    >
    </topbar-item>


    <topbar-item
      v-if="isMarvinRecruiter"
      :tabs-path="Object.values(routeTabs.admin)"
      class="is-unshrink mt-4"
      :iconComponent="isItemActive(Object.values(routeTabs.admin)) ? 'settings-bold' : 'settings'"
      label="Admin"
      to="/organizations?page=1"
    >
    </topbar-item>
  </topbar-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { routeTabs } from '@/router';
import TopbarContainer from '@/components/Topbar/TopbarContainer';
import TopbarItem from '@/components/Topbar/TopbarItem.vue';
import { mapStores } from 'pinia';
import { useLaunchDarklyStore } from '@/store/pinia/launchDarkly';

export default {
  name: 'topbar',
  components: {
    TopbarItem,
    TopbarContainer,
  },
  props: {
    isNotificationsOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHover: false,
      windowWidth: 0,
      routeTabs,
    };
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
      coaches: state => state.coaches.coaches,
    }),
    ...mapStores(useLaunchDarklyStore),
    ...mapGetters({
      configurationFramework: 'user/configurationFramework',
    }),
    isMarvinRecruiter() {
      return this.$store.getters['user/isMarvinRecruiter'];
    },
    isSuperUser() {
      return this.$store.getters['user/isSuperUser'];
    },
    isOrganizationException() {
      return this.$store.getters['user/isOrganizationException'];
    },
    opportunityLink() {
      if (this.isOrganizationException) {
        return `/pipe/hunt?selected-pipe=global-pipe&job-active=true&coachId=${this.coaches
          .map(c => c._id)
          .join(',')}`;
      }
      return `/pipe/hunt?selected-pipe=personal-pipe&job-active=true&coachId=${this.profile._id}`;
    },
    processesStatsLink() {
      return `/pipe/processes-statistics?activityCoachId=${this.profile._id}`;
    },
    remindersLink() {
      return `/reminders?activityCoachId=${this.profile._id}&filter=todo`;
    },
    listsLink() {
      return `/favorite-lists?list-type=company`;
    },
    isCampaignsVisible() {
      return this.launchDarklyStore?.getFlag('enableCampaigns') ?? false;
    },
    isBillingVisible() {
      return this.launchDarklyStore?.getFlag('enableBilling') ?? false;
    },
    isOnboardingVisible() {
      return !this.profile?.onboarding?.hideOnboardingPage;
    }
  },
  created() {
    this.setWindowWidth();

    window.addEventListener('resize', this.setWindowWidth);
    this.$on('hook:destroyed', () => {
      window.removeEventListener('resize', this.setWindowWidth);
    });
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    isItemActive(tabsPath) {
      return tabsPath.includes(this.$route.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  height: calc(100% - var(--app-bar-height));
  position: fixed;
  left: 0;
  top: 0;
  max-width: 85px;
  transition: 0.3s;
  z-index: 5;
  width: 100%;
  overflow: auto;
  transform: translateY(var(--app-bar-height));
  border-right: 1px solid $color-blue-light;

  &--active {
    max-width: 230px;
  }

  &__logo {
    overflow: hidden;
  }

  &__help {
    margin-top: auto;
    bottom: 85px;
    right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 2.3em;
    font-weight: bold;
    z-index: 100;
    transition: 0.15s;

    &:focus,
    &:visited {
      color: $color-secondary;
    }

    &:hover {
      background: $color-secondary;
      color: $color-white;
      cursor: pointer;
    }

    @include bp("tablet") {
      display: flex;
    }
  }
}
</style>